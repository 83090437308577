import { graphql } from "gatsby"
import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import Quote from "src/components/Quote"
import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = createUseStyles({
  content: {
    width: `60%`,
    margin: `auto`,
    textAlign: `center`,
  },
  h4: {
    fontSize: `calc(0.5vh + 1rem)`,
    fontWeight: `normal`,
    marginBottom: `0.5rem`,
  },
  threeCol: {
    width: `100%`,
    marginBottom: `1em`,
  },
  threeColContainer: {
    display: `flex`,
    margin: `0px -30%`,
  },
  ul: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `center`,
    listStyleType: `disc`,
    marginBottom: `1em`,
  },
  li: {
    margin: `0px 20px`,
  },
  "@media (max-width: 850px)": {
    content: {
      width: `100%`,
    },
    threeColContainer: {
      flexDirection: `column`,
      margin: `0px 0px`,
    },
  },
})

interface Props {
  data: GatsbyTypes.StoryQuery
}

const StoryPage = ({ data }: Props) => {
  const classes = useStyles()
  const [height, setHeight] = useState<number>(0)

  return (
    <Layout setHeaderHeight={setHeight}>
      <SEO title="Our Story" />
      <main
        style={{
          // height: `100vh`,
          marginTop: `${height}px`,
          padding: `6.6vmax 4vw 4vw`,
          boxSizing: "border-box",
        }}
      >
        <div className={classes.content}>
          <h1 style={{ marginBottom: 17 }}>Our Story</h1>
          {data.contentfulQuote != null && (
            <Quote data={data.contentfulQuote} />
          )}
          {data.contentfulStory?.text?.childMarkdownRemark?.rawMarkdownBody !=
            null &&
            data.contentfulStory?.text?.childMarkdownRemark?.rawMarkdownBody
              .split("\n\n")
              .map(paragraph => {
                return (
                  <p style={{ fontSize: 20, lineHeight: 1.6 }}>{paragraph}</p>
                )
              })
              .reduce((accu: JSX.Element[], elem) => {
                return accu.length === 0 ? [elem] : [...accu, <br />, elem]
              }, [])}
        </div>
      </main>
    </Layout>
  )
}

export default StoryPage

export const pageQuery = graphql`
  query Story {
    contentfulQuote(pageId: { eq: "story" }) {
      ...Quote
    }
    contentfulStory {
      text {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`
